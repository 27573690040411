import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"

export const query = graphql`
  query($Slug: String) {
    sanityPost(slug: { current: { eq: $Slug } }) {
      title
      publishedAt(formatString: "DD. MMMM YYYY", locale: "da_DK")
      _rawBody

      mainImage {
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const Insights = props => {
  return (
    <Layout>
      <SEO
        title={props.data.sanityPost.title}
        description={getDescription(props)}
        // image={props.data.sanityPost.mainImage.asset.fluid.src}
      />

      <section className="postSection">
        <h1>{props.data.sanityPost.title}</h1>
        <p className="date">{props.data.sanityPost.publishedAt}</p>
        <Img fluid={props.data.sanityPost.mainImage.asset.fluid} />
        <BlockContent
          blocks={props.data.sanityPost._rawBody}
          projectId="q2jj4zmh"
          dataset="production"
          serializers={serializers}
        />
      </section>
    </Layout>
  )
}

function getDescription(props) {
  return props.data.sanityPost._rawBody[0].children[0].text
}

export default Insights
